import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class Svg404 extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['mouseMove']);
        this.addEvents();
    }

    addEvents() {

        window.addEventListener('mousemove', this.mouseMove);
    }

    removeEvents() {
    }

    mouseMove(e){
        var rect = this.el.querySelector('#quatrecentquatre-website-404-u-iris').getBoundingClientRect();

        let movementX = rect.left - (rect.right - rect.left) - e.clientX;
        let movementY = rect.bottom - (rect.top - rect.bottom) - e.clientY;

        gsap.to(this.el.querySelector('#quatrecentquatre-website-404-s-g1'),{
            x:Math.max(-movementX/20, -40),
            y:Math.max(-movementY/20, -15),
            duration: 0.033,
            overwrite: true
        })
        gsap.to(this.el.querySelector('#quatrecentquatre-website-404-u-iris'),{
            x:Math.max(-movementX/30, -30),
            y:Math.max(-movementY/30, -15),
            duration: 0.033,
            overwrite: true
        })
    }
}

Me.views['Svg404'] = Svg404;
