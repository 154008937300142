import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class TeamListing extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['showPicture', 'hideAllPictures']);

        this.addEvents();
    }

    addEvents() {
        const isTouchDevice = this.isTouchDevice();
        const items = gsap.utils.toArray(
            this.el.querySelectorAll('.list-item'),
        );
        if (items.length != 0) {
            items.forEach((item) => {
                if (isTouchDevice) {
                    // Use scroll to show pictures on mobile
                    ScrollTrigger.create({
                        trigger: item,
                        start: 'top center',
                        end: 'bottom center',
                        toggleClass: 'active',
                    });
                } else {
                    // Show pictures on hover on desktop
                    item.addEventListener('mouseenter', this.showPicture);
                    this.el
                        .querySelector('.list-wrapper-team')
                        .addEventListener('mouseleave', this.hideAllPictures);
                }
            });
        }
    }

    removeEvents() {
        const items = gsap.utils.toArray(
            this.el.querySelectorAll('.list-item'),
        );
        if (items.length != 0) {
            items.forEach((item) => {
                item.removeEventListener('mouseenter', this.showPicture);
                this.el
                    .querySelector('.list-wrapper-team')
                    .removeEventListener('mouseleave', this.hideAllPictures);
            });
        }
    }

    showPicture(event) {
        this.hideAllPictures();
        event.target.classList.toggle('active');
    }

    hideAllPictures() {
        this.el
            .querySelectorAll('.active')
            .forEach((i) => i.classList.remove('active'));
    }

    isTouchDevice() {
        return (
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        );
    }
}

Me.views['TeamListing'] = TeamListing;
