import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class ArticleFiltering extends ViewBasic {
    constructor(options) {
        super(options);

        this.currentFilterValue = '';
        this.filters = this.el.querySelectorAll('.filter');
        this.displayedValues = this.el.querySelectorAll('.list-value');
        this.classes = {
            hide: 'hide',
        };
        this.articleWrappers = this.el.querySelectorAll(
            '.articles-list-wrapper',
        );
    }

    initialize() {
        this.bindAll([
            'changeHandler',
        ]);

        this.addEvents();

        //Added to support #hash to preselect a filter (used mainly in articles and the blog)
        if(window.location.hash) {
            // Fragment exists
            this.filters.forEach((element) => {
                //find the hash as a value of one of the filter
                if(element.value.indexOf(window.location.hash.replace('#','')) != -1){
                    //trigger click and select the filter
                    element.click();
                    setTimeout(function(){
                        //scroll to the filter
                        window.lenis.scrollTo(element.getBoundingClientRect().top, {
                            offset: -100,
                            duration: 1,
                            easing: function easeInOutSine(x) {
                                return -(Math.cos(Math.PI * x) - 1) / 2;
                            }
                        });
                    },100);

                }
            });
        }
    }

    addEvents() {
        this.filters.forEach((element) => {
            element.addEventListener('change', this.changeHandler);
        });
    }

    removeEvents() {
        this.filters.forEach((element) => {
            element.removeEventListener('change', this.changeHandler);
        });
    }

    changeHandler(e) {
        this.currentFilterValue = e.currentTarget.value;
        this.updateDisplayed();
    }

    updateDisplayed() {
        this.displayedValues.forEach((element) => {
            if (this.currentFilterValue === '') {
                element.classList.remove(this.classes.hide);
            } else {
                //find the selected filter in the value of the item value. Since the item could have more than 1 value, we ook for the filter inside the whole value and find a match.
                if (
                    element.getAttribute('data-filter-type') &&
                    element.getAttribute('data-filter-type').toLowerCase().indexOf(this.currentFilterValue) != -1
                ) {
                    element.classList.remove(this.classes.hide);
                } else {
                    element.classList.add(this.classes.hide);
                }
            }
        });

        let event = new CustomEvent('Projects.ContentChanged');
        window.dispatchEvent(event);
    }
}

Me.views['ArticleFiltering'] = ArticleFiltering;
