import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class ScrollingProjects extends ViewBasic {
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        this.bindAll(['resize']);
        //make sure we have an unique id for each component since we could have more than one in a page.
        //we need unique id to kill the timeline on resize;
        this._uid = Math.random()*10000;
        this.addEvents();

        gsap.from(this.el.querySelector('.scrolling-section .images'), {
            borderRadius: 0,
            scrollTrigger: {
                trigger: this.el.querySelector('.scrolling-section .images'),
                start: 'top 50%',
                end: 'bottom bottom',
                scrub: false,
            },
        });

        this.initTimeline();

        this.resize();
    }

    initTimeline() {
        let $element = this.el;

        if(this.tl){
            ScrollTrigger.getById("project-list-scrolling" + this._uid).kill(true);
            gsap.set(".scaling-logo > div", {clearProps: true});
            this.tl.clear(true);
            this.tl = null;
        }

        this.tl = gsap.timeline({
            scrollTrigger: {
                id: 'project-list-scrolling' + this._uid,
                trigger: $element.querySelector('.scrolling-section'),
                start: '-115px',
                end: `bottom bottom-=${this.endScrollTriggerGap}px`,
                scrub: false,
                //markers: true,
                onUpdate: (self) => {
                    let progress = self.progress.toFixed(3);

                    if (progress < 0.5) {
                        $element.querySelector('.count .value').innerHTML = 1;
                    } else if (progress < 0.99) {
                        $element.querySelector('.count .value').innerHTML = 2;
                    } else {
                        $element.querySelector('.count .value').innerHTML = 3;
                    }

                    $element
                        .querySelectorAll('.images-section a')
                        .forEach((image, index) => {
                            //only animate first image if progress is bigger than 0 (always)
                            //only animate 2nd image if progress is bigger than .50 (halfway during the section scrolltrigger)
                            //never animate 3rd image, it will always stick underneath everything and move with the content at the end(position sticky)
                            if (index * 0.5 < progress) {
                                if (index == 1) {
                                    //2nd image works differently, we wanna translate it following the scroll, but we need to add half the container height (minus the screenheight). So once we are at halfway, it's like we starting from 0 again.

                                    //@note dom: Le calcul ici commence a être compliquer. Je crois que le scrolltrigger a besoin d'être centrer dans l'écran pour bien calculer le tout. puisqu'on a un top de 150 et que le bas varie selon la hauteur du conteneur d'image, des fois l'image shift down un peu du a la différence. Donc j'ai ajouter le offsetGap qui est la valeur en bas et je la soustrait au 115px static du haut du container
                                    image.style.transform = `translateY(${
                                        $element.querySelector('.scrolling-section').getBoundingClientRect().top +
                                        $element.querySelector('.scrolling-section').offsetHeight / 2 -
                                        (window.innerHeight + (115 - this.endScrollTriggerGap)) / 2
                                    }px)`;
                                } else {
                                    image.style.transform = `translateY(${
                                        $element
                                            .querySelector('.scrolling-section')
                                            .getBoundingClientRect().top - 115
                                    }px)`;
                                }
                            } else {
                                image.style.transform = `translateY(0px)`;
                            }
                        });
                    //debug
                    //console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
                },
            },
        });
    }

    addEvents() {
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);
    }

    resize(){
        this.endScrollTriggerGap = window.innerHeight - this.el.querySelector('.images').offsetHeight - 115;
        this.initTimeline();
    }
}

Me.views['ScrollingProjects'] = ScrollingProjects;
