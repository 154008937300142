import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

export class Hero extends ViewBasic {
    constructor(options) {
        super(options);

        this.windowWidth = null;
        this.heroLines = null;
        this.h1 = this.el.querySelector('h1');
        this.button = this.el.querySelector('.button-wrapper');

    }

    initialize() {
        this.bindAll([
            'scroll',
            'resize',
            'setPositionForButton',
            'initAnimations',
            'initTitleAnimations',
        ]);
        this.addEvents();
        this.resize();
        this.initAnimations();
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll);
        window.addEventListener('resize', this.resize);
    }

    removeEvents() {
        window.removeEventListener('scroll', this.scroll);
        window.removeEventListener('resize', this.resize);
    }

    setPositionForButton() {
        this.h1Styles = window.getComputedStyle(this.h1);
        this.h1MarginBottom = this.h1Styles.getPropertyValue('margin-bottom');

        this.button.style.bottom = this.h1MarginBottom;
    }

    initAnimations() {
        const hero = this.el;

        const element = this.el.querySelector('.animated-svg svg');
        const player = element ? element.svgatorPlayer : null;

        this.svgProgress = null;
        this.gsapDuration = 2.5;
        let scope = this;

        if (player) {
            player.seek(0);
            setTimeout(function () {
                player.play();
            }, 500);

            gsap.to(this, { gsapDuration: 0.033, duration: 3 });

            this.animatedSVG = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    start: 'top top',
                    end: 'bottom top+=200',
                    invalidateOnRefresh: true,
                    scrub: false,
                    markers: false,
                    onUpdate: (self) => {
                        player.pause();
                        if (scope.svgProgress == null) {
                            scope.svgProgress =
                                (player.currentTime / player.totalTime) * 100;
                        }

                        gsap.to(scope, {
                            svgProgress: 100 - self.progress * 100,
                            duration: scope.gsapDuration,
                            ease: 'linear',
                            onUpdate: function () {
                                player.seek(scope.svgProgress);
                            },
                        });
                    },
                },
            });
        }

        const animatedSvg = hero.querySelector('.animated-svg');
        if (animatedSvg) {
            // Animated svgs are usually bigger so the breakpoint for mobile is much increased
            let xCoord =
                window.innerWidth < 1300
                    ? animatedSvg.dataset.mobileX
                    : animatedSvg.dataset.x;
            let yCoord =
                window.innerWidth < 1300
                    ? animatedSvg.dataset.mobileY
                    : animatedSvg.dataset.y;

            // Ajusts the yCoord especially if the hero is not 100vh
            yCoord = Math.ceil(
                (this.el.offsetHeight / window.innerHeight) * yCoord,
            );

            // Set the horizontal alignment based on the data attribute
            gsap.set(animatedSvg, { x: xCoord + 'vw' });

            // Computes a random rotation (range)
            let angle = 18;
            let randomRotation = Math.random() * (angle - -angle) + -angle;

            // "Drop" animation
            gsap.fromTo(
                animatedSvg,
                {
                    y: '100vh',
                    rotate: randomRotation,
                },
                {
                    y: yCoord + 'vh',
                    rotate: 0,
                    duration: 1.5,
                    delay: 0.3,
                    onComplete() {
                        // Parallax
                        gsap.to(animatedSvg, {
                            scrollTrigger: {
                                trigger: hero,
                                start: 'top top',
                                end: 'bottom top',
                                invalidateOnRefresh: true,
                                scrub: 1,
                            },
                            y: (i, el) =>
                                (1 - parseFloat(el.dataset.speed)) *
                                (yCoord / 100) *
                                hero.getBoundingClientRect().height,
                            ease: 'none',
                        });
                    },
                },
            );
        }

        const illustrations = hero.querySelectorAll('.illustration');
        if (illustrations.length) {
            illustrations.forEach((illustration, index) => {
                let xCoord =
                    window.innerWidth < 768
                        ? illustration.dataset.mobileX
                        : illustration.dataset.x;
                let yCoord =
                    window.innerWidth < 768
                        ? illustration.dataset.mobileY
                        : illustration.dataset.y;

                // Ajusts the yCoord especially if the hero is not 100vh
                yCoord = Math.ceil(
                    (this.el.offsetHeight / window.innerHeight) * yCoord,
                );

                // Set the horizontal alignment based on the data attribute
                gsap.set(illustration, { x: xCoord + 'vw' });

                // Computes a random rotation
                // No range because there seem to be rendering issues with Chrome
                let angle = 15;
                let randomRotation = Math.random() < 0.5 ? -angle : angle;

                // "Drop" animation
                gsap.fromTo(
                    illustration,
                    {
                        y: '-' + illustration.offsetHeight - 30,
                        rotate: randomRotation,
                    },
                    {
                        y: yCoord + 'vh',
                        rotate: 0,
                        duration: 1.5,
                        delay: 0.3 * (index + 1),
                        onComplete() {
                            // Parallax
                            gsap.to(illustration, {
                                scrollTrigger: {
                                    trigger: hero,
                                    start: 'top top',
                                    end: 'bottom top',
                                    invalidateOnRefresh: true,
                                    scrub: 1,
                                },
                                y: (i, el) =>
                                    (1 - parseFloat(el.dataset.speed)) *
                                    (yCoord / 100) *
                                    hero.getBoundingClientRect().height,
                                ease: 'none',
                            });
                        },
                    },
                );
            });
        }

        const listWrapper = hero.querySelector('.list-wrapper ul');
        if (listWrapper) {
            gsap.set(listWrapper, { opacity: 1 });

            // Drop and wiggle animation
            const items = listWrapper.querySelectorAll('li');
            gsap.to(items, {
                y: 0,
                rotate: 0,
                duration: 1.5,
                stagger: {
                    each: 0.08,
                    from: 'end',
                },
                onComplete() {
                    if (window.innerWidth >= 768) {
                        // Parallax on each item with stagger
                        gsap.to(items, {
                            scrollTrigger: {
                                trigger: hero,
                                start: 'top top',
                                end: 'bottom top',
                                invalidateOnRefresh: true,
                                scrub: 0.5,
                            },
                            y: (index) => -300 + (index + 1) * 25,
                            rotate: () => Math.floor(Math.random() * 6) - 3,
                            stagger: 0.1,
                            ease: 'none',
                        });
                    }
                },
            });
        }

        this.initTitleAnimations();
    }

    initTitleAnimations() {
        document.fonts.ready.then(
            function () {
                const hero = this.el;

                // Split text by line with GSAP plugin
                this.heroLines = new SplitText(hero.querySelector('h1'), {
                    type: 'lines',
                });

                gsap.set(hero.querySelector('h1'), { opacity: 1 });

                const easing = 'power3.inOut';
                let delay = 0;

                const link = hero.querySelector('.back-btn');
                if (link) {
                    gsap.to(link, {
                        duration: 1,
                        opacity: 1,
                        ease: easing,
                    });
                    delay = 0.2;
                }

                // Animate title in hero
                gsap.to(this.heroLines.lines, {
                    duration: 1,
                    delay: delay,
                    stagger: 0.1,
                    opacity: 1,
                    y: 0,
                    ease: easing,
                });

                // Animate subtitle in hero
                const paragraphs = hero.querySelectorAll('.hero p');
                if (paragraphs.length) {
                    gsap.to(paragraphs, {
                        duration: 1,
                        delay: delay + 0.5 + this.heroLines.lines.length * 0.1,
                        stagger: 0.1,
                        opacity: 1,
                        y: 0,
                        ease: easing,
                    });
                }

                const form = hero.querySelector('form');
                if (form) {
                    gsap.to(form, {
                        duration: 1,
                        delay: delay + 0.6 + this.heroLines.lines.length * 0.1,
                        opacity: 1,
                        y: 0,
                        ease: easing,
                    });
                }

                const image = hero.querySelector('.hero-image');
                if (image) {
                    const primaryLink = hero.querySelector('.primary-btn');
                    if (primaryLink) {
                        gsap.to(primaryLink, {
                            duration: 1,
                            delay:
                                delay + 0.2 + this.heroLines.lines.length * 0.1,
                            opacity: 1,
                            y: 0,
                            ease: easing,
                        });
                    }

                    gsap.to(image, {
                        duration: 1,
                        delay: delay + 0.6 + this.heroLines.lines.length * 0.1,
                        opacity: 1,
                        y: 0,
                        ease: easing,
                    });
                }
            }.bind(this),
        );
    }

    resize() {
        // Do not fire the resize calculations if the resize is just on the window height
        if (window.innerWidth === this.windowWidth) {
            return false;
        }
        this.windowWidth = window.innerWidth;

        //calculate ratio of container versus page width, apply this value to a css variable so we can use it in a scale on the hero to the same width as the content width;
        document.documentElement.style.setProperty(
            '--container-width-percentage',
            this.el.querySelector('.centered-content').offsetWidth /
                window.innerWidth,
        );

        // Adjust illustrations X coordinates
        const illustrations = this.el.querySelectorAll('.illustration');
        if (illustrations.length) {
            illustrations.forEach((illustration) => {
                let xCoord =
                    window.innerWidth < 768
                        ? illustration.dataset.mobileX
                        : illustration.dataset.x;
                let yCoord =
                    window.innerWidth < 768
                        ? illustration.dataset.mobileY
                        : illustration.dataset.y;

                yCoord = Math.ceil(
                    (this.el.offsetHeight / window.innerHeight) * yCoord,
                );

                // Set the horizontal alignment based on the data attribute
                gsap.set(illustration, { x: xCoord + 'vw', y: yCoord + 'vh' });
            });
        }

        // Adjust animated svg X coordinates
        const animatedSvg = this.el.querySelector('.animated-svg');
        if (animatedSvg) {
            // Animated svgs are usually bigger so the breakpoint for mobile is much increased
            let xCoord =
                window.innerWidth < 1300
                    ? animatedSvg.dataset.mobileX
                    : animatedSvg.dataset.x;
            let yCoord =
                window.innerWidth < 1300
                    ? animatedSvg.dataset.mobileY
                    : animatedSvg.dataset.y;

            // Ajusts the yCoord especially if the hero is not 100vh
            yCoord = Math.ceil(
                (this.el.offsetHeight / window.innerHeight) * yCoord,
            );

            // Set the horizontal alignment based on the data attribute
            gsap.set(animatedSvg, { x: xCoord + 'vw', y: yCoord + 'vh' });
        }

        if (this.heroLines) {
            this.heroLines.revert();
            this.heroLines = null;

            this.initTitleAnimations();
        }


        if(this.button) {
            this.setPositionForButton();
        }
    }

    scroll() {
        if (
            document.documentElement.scrollTop > 300 &&
            !this.el.classList.contains('with-image')
        ) {
            this.el.classList.add('scrolled');
        } else {
            this.el.classList.remove('scrolled');
        }
    }
}

Me.views['Hero'] = Hero;
