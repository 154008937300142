import { ViewBasic } from '@quatrecentquatre/manage-me';

export class ImageVideo extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['scroll']);
        this.addEvents();
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll);
    }

    removeEvents() {
        window.removeEventListener('scroll', this.scroll);
    }

    scroll() {
        const currentBlock = this.el;
        const isBlockScrolled = document.documentElement.scrollTop >= currentBlock.offsetTop + currentBlock.clientHeight - window.innerHeight;

        if (isBlockScrolled) {
            currentBlock.classList.add('scrolled');
        } else {
            currentBlock.classList.remove('scrolled');
        }
    }
}

Me.views['ImageVideo'] = ImageVideo;
