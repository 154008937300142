import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

export class CoolText extends ViewBasic {
    constructor(options) {
        super(options);
        //set default variables

        this.mouse = null;
        this.thumbnail = null;
        this.textLines = null;
        this.windowWidth = null;
    }

    initialize() {
        this.bindAll([
            'showThumbnailEvent',
            'hideThumbnailEvent',
            'mouseMoveEvent',
            'initAppearAnimations',
            'resize',
        ]);
        this.thumbnail = this.el.querySelector('.thumbnail');
        this.windowWidth = window.innerWidth;

        this.initAppearAnimations();
        this.addEvents();
    }

    addEvents() {
        let scope = this;

        const links = this.el.querySelectorAll('.text a');
        links.forEach((link) => {
            let url = link.href;
            // Remove href to avoid click but we let events for mouse and focus
            if((url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.gif') || url.endsWith('.jpeg'))){

                link.dataset.media = link.href;

                if(url.indexOf('?image=') == -1){
                    //image only will not be seen like a link
                    link.removeAttribute('href');
                    link.setAttribute('role', 'link');
                    link.setAttribute('tabindex', '0');
                    link.classList.add('no-underline');
                }else{
                    //removes image from the url as its stored in the dataset media link
                    link.setAttribute('href', link.href.split('?image=')[0]);
                }

                link.addEventListener('mouseenter', scope.showThumbnailEvent);
                link.addEventListener('focusin', scope.showThumbnailEvent);

                link.addEventListener('mouseleave', scope.hideThumbnailEvent);
                link.addEventListener('focusout', scope.hideThumbnailEvent);
            }


        });

        gsap.set(this.thumbnail, { xPercent: -50, yPercent: -50 });

        const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        this.mouse = { x: pos.x, y: pos.y };
        // You can tweak this value
        const speed = 0.2;

        const xSet = gsap.quickSetter(this.thumbnail, 'x', 'px');
        const ySet = gsap.quickSetter(this.thumbnail, 'y', 'px');

        window.addEventListener('mousemove', scope.mouseMoveEvent);

        gsap.ticker.add(() => {
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

            pos.x += Math.min(
                (this.mouse.x - pos.x + this.thumbnail.clientWidth / 2) * dt,
                window.innerWidth - pos.x - this.thumbnail.clientWidth / 2, // Right limit of the screen
            );
            pos.y += Math.max(
                (this.mouse.y - pos.y - this.thumbnail.clientHeight / 2) * dt,
                0 - pos.y + this.thumbnail.clientHeight / 2, // Top limit of the screen
            );

            xSet(pos.x);
            ySet(pos.y);
        });

        window.addEventListener('resize', scope.resize);
    }

    removeEvents() {
        let scope = this;

        const links = this.el.querySelectorAll('.text a');
        links.forEach((link) => {
            link.removeEventListener('mouseenter', scope.showThumbnailEvent);
            link.removeEventListener('focusin', scope.showThumbnailEvent);

            link.removeEventListener('mouseleave', scope.hideThumbnailEvent);
            link.removeEventListener('focusout', scope.hideThumbnailEvent);
        });

        window.removeEventListener('mousemove', scope.mouseMoveEvent);
        window.removeEventListener('resize', scope.resize);
    }

    showThumbnailEvent(event) {
        let html = '';
        let url = event.target.dataset.media;
;
        if (url.endsWith('.mp4')) {
            html = `<video muted autoplay loop src="${url}"></video>`;
        } else if(url.indexOf('.jpg') != -1 || url.indexOf('.png') != -1 || url.indexOf('.gif') != -1 || url.indexOf('.jpeg') != -1) {
            //if image is a param, use the param 2 which is an image link, and first part is an url to a page.
            if(url.indexOf('?image')!= -1){
                html = `<img src="${url.split('?image=')[1]}" alt="">`;
            }else{
                //otherwise use the dataset url provided (image)
                html = `<img src="${url}" alt="">`;
            }
        }
        this.thumbnail.innerHTML = html;
        gsap.to(this.thumbnail, { opacity: 1 });
    }

    hideThumbnailEvent() {
        gsap.to(this.thumbnail, { opacity: 0 });
    }

    mouseMoveEvent(event) {
        this.mouse.x = event.x;
        this.mouse.y = event.y;
    }

    initAppearAnimations() {
        const text = this.el.querySelector('.text');
        const paragraphs = text.querySelectorAll('p');
        const links = text.querySelectorAll('a');
        if (paragraphs.length) {
            document.fonts.ready.then(
                function () {
                    this.textLines = new SplitText(paragraphs, {
                        type: 'lines',
                    });

                    paragraphs.forEach((paragraph) => {
                        const lines = gsap.utils.toArray(
                            paragraph.querySelectorAll('div'),
                        );
                        lines.forEach((line) => {
                            gsap.to(line, {
                                duration: 0.4,
                                opacity: 1,
                                y: 0,
                                scrollTrigger: {
                                    trigger: line,
                                    start: 'top bottom',
                                    end: 'bottom center',
                                },
                            });
                        });
                    });

                    // To animate the numbers in the "links"
                    if (links.length) {
                        links.forEach((link) => {
                            // Wrap numbers with a span
                            link.innerHTML = link.innerText.replace(
                                /\d+/g,
                                '<span class="num">$&</span>',
                            );

                            const number = link.querySelector('.num');

                            if (number) {
                                // Set the (min) width to avoid jumps when the number changes
                                gsap.set(number, {
                                    minWidth: number.offsetWidth,
                                    width: number.offsetWidth,
                                });

                                // If 4 numbers we assume it's the year so we animate from the current year instead
                                const numCharacters = number.innerText.length;
                                const isYear = numCharacters === 4;
                                gsap.from(number, {
                                    duration: 3,
                                    delay: isYear ? 0.2 : 0,
                                    innerText: isYear
                                        ? new Date().getFullYear()
                                        : numCharacters < 3
                                        ? 0
                                        : 100,
                                    snap: {
                                        innerText: 1,
                                    },
                                    ease: 'power2.out',
                                    scrollTrigger: {
                                        trigger: link,
                                        start: 'top bottom',
                                        end: 'bottom center',
                                    },
                                });
                            }
                        });
                    }
                }.bind(this),
            );
        }
    }

    resize() {
        // Do not fire the resize calculations if the resize is just on the window height
        if (window.innerWidth === this.windowWidth) {
            return false;
        }
        this.windowWidth = window.innerWidth;

        if (this.textLines) {
            this.textLines.revert();
            this.textLines = null;
        }

        this.initAppearAnimations();
    }
}

Me.views['CoolText'] = CoolText;
