import { string } from 'yup';
import { FormBase } from '@quatrecentquatre/form-me';
import { mixed } from 'yup';

export class ContactForm extends FormBase {
    constructor(options) {
        super(options);
        //set default variables

        this.fieldName = this.$el.querySelector('[name="fields[form_name]"]');
        this.fieldEmail = this.$el.querySelector('[name="fields[form_email]"]');
        this.fieldMessage = this.$el.querySelector('[name="fields[form_message]"]');
        this.fieldFile = this.$el.querySelector('[name="fields[form_files]"]');

        const MAX_FILE_SIZE = 10000000;
        const supportedFormats = ['doc', 'docx', 'txt', 'pdf'];

        this.addFields([
            {
                name: 'fields[form_name]',
                validationSchema: string().required(),
                $error: this.fieldName.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_email]',
                validationSchema: string().email().required(),
                $error: this.fieldEmail.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_message]',
                validationSchema: string().required(),
                $error: this.fieldMessage.parentElement.querySelector('.error-msg'),
            },
            {
                name: 'fields[form_files]',
                validationSchema: mixed()
                    .test('is-valid-size', function (value) {
                        if (!value || !value.name) return true; // Skip this validation if empty
                        return value.size <= MAX_FILE_SIZE;
                    })
                    .test('is-valid-format', function (value) {
                        if (!value || !value.name) return true;
                        return supportedFormats.includes(
                            value.name.split('.').pop(),
                        );
                    })
                    .notRequired(),
                $error: this.fieldFile.parentElement.querySelector(
                    '.error-msg',
                ),
                $targetErrorClass: this.$el.querySelector('.file-input'),
            },
        ]);
    }

    initialize() {
        super.initialize();
        this.button = this.$el.querySelector('button[type="submit"]');
    }

    /**
     * Scroll to error position. Query selector finds first error.
     */

    handleValidationError() {
        super.handleValidationError()

        // Scroll to position of error.
        const targetElement = this.$el.querySelector('.has-error');
        const { top } = targetElement.getBoundingClientRect();
        const targetOffset = window.scrollY + top - 200;

        window.scroll({
            top: targetOffset,
            behavior: 'smooth'
        });

    }

    handleAjaxSend(e) {
        this.button.disabled = true
        this.addLoader();

        super.handleAjaxSend(e);
    }

    ajaxError(error) {
        this.$el.querySelector('.success-message').classList.add('hide');
        this.$el.classList.add(this.classes.serverError);
        this.$el.querySelector('.error-message').innerText = this.$el.querySelector('.error-message').getAttribute('data-default');
        this.$el.querySelector('.error-message').classList?.remove('hide');
    }

    ajaxSuccess(response) {

        if (response.success) {
            // Reset form
            this.$el.reset();

            // Remove has-value classes from all form fields
            let hasValue = this.$el.querySelectorAll('.has-value')
            if(hasValue){
                hasValue.forEach((entry) => {
                    entry.classList.remove('has-value');
                })
            }

            // Show success message, hide error if visible
            this.$el.querySelector('.success-message').classList?.remove('hide');
            this.$el.querySelector('.error-message').classList?.add('hide');
        } else {
            // Show error message, hide success if visible
            this.$el.querySelector('.success-message').classList?.add('hide');
            this.$el.querySelector('.error-message').innerText = response.errors[0];
            this.$el.querySelector('.error-message').classList?.remove('hide');
        }
    }

    ajaxComplete() {
        super.ajaxComplete();
        this.removeLoader();

        // Enable submit button
        this.button.disabled = false;

        setTimeout(() =>{
            this.$el.querySelector('.success-message').classList?.add('hide');
        },5000)
    }

    resetFieldState(field){
        // Remove has-error from field
        super.resetFieldState(field);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.remove(this.classes.error)
        }
    }
    setFieldState(field, error){
        // Remove has-error to parent field (used for $targetErrorClass at line 22)
        super.setFieldState(field, error);
        if(field.$targetErrorClass){
            field.$targetErrorClass.classList.add(this.classes.error)
        }
    }

    addLoader(){
        const lang = document.querySelector('html').getAttribute('lang');
        const loaderText = lang === 'fr' ? 'Chargement...' : 'Loading...';

        const spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        const loader = document.createElement('span');
        loader.className = 'loader';

        // spinnerBorder.appendChild(loader);
        spinnerWrapper.appendChild(loader);

        this.$el.appendChild(spinnerWrapper);
    };

    removeLoader() {
        this.$el.parentNode.querySelector('.spinner-wrapper')?.remove();
    }


}

Me.forms['ContactForm'] = ContactForm;
