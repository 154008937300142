import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class Footer extends ViewBasic {
    constructor(options) {
        super(options);

        this.contactSVG = this.el.querySelector('.contact-svg svg');
        this.svgatorPlayer = this.contactSVG ? this.contactSVG.svgatorPlayer : null;

        this.windowWidth = null;
    }

    initialize() {
        this.bindAll(['resize', 'initScrollTrigger', 'focusContactBanner', 'eventLogoScalingProgress']);
        this.addEvents();

        this.resize();

        // Trigger a resize before reaching the footer
        ScrollTrigger.create({
            trigger: this.el,
            start: 'top-=500px bottom',
            end: 'bottom bottom',
            once: true,
            onEnter: () => this.resize(true)
        });

        // TODO: réduire un peu la vélocité du smoothscroll à l'arrivée sur le logo
        // pour éviter qu'on arrive en un coup de swipe au logo complet
    }

    addEvents() {
        if(this.el.querySelector('.contact-footer')){
            this.el.querySelector('.contact-footer').addEventListener('mouseenter', this.focusContactBanner);
        }
        window.addEventListener('resize', this.resize);
        window.addEventListener('Projects.ContentChanged', this.initScrollTrigger);
    }

    removeEvents() {
        if (this.tween) {
            ScrollTrigger.getById('logo-footer').kill();
            gsap.set('.scaling-logo > div', { clearProps: true });
            this.tween.kill();
            this.tween = null;
        }

        if(this.el.querySelector('.contact-footer')){
            this.el.querySelector('.contact-footer').removeEventListener('mouseenter', this.focusContactBanner);
        }
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('Projects.ContentChanged', this.initScrollTrigger);
    }

    initScrollTrigger(){
        if (this.tween) {
            ScrollTrigger.getById('logo-footer').kill();
            gsap.set('.scaling-logo > div', { clearProps: true });
            this.tween.kill();
            this.tween = null;
        }

        const contactSvgWrapper = this.el.querySelector('.contact-svg');
        if (contactSvgWrapper) {
            gsap.to(contactSvgWrapper, {
                duration: 0,
                scrollTrigger: {
                    trigger: contactSvgWrapper,
                    start: 'top-=100px center',
                    onEnter: self => {
                        if(this.svgatorPlayer){
                            if(this.svgatorPlayer.currentTime == 0){
                                this.svgatorPlayer.play();
                            }
                        }
                    }
                }
            });
        }

        let height = window.innerWidth < 768 ? 70 : 80;
        // if (this.el.classList.contains('footer-small')) {
            // We just need to substract the .extra-infos in .footer-bottom, not .scaling-logo (see resize() function)
            // let gapMinusLogo = this.gap + this.el.querySelector('.scaling-logo').offsetHeight;
            // Get height in %
            // height = (gapMinusLogo / window.innerHeight) * 100;
        // }

        this.tween = gsap.to('.scaling-logo > div', {
            height: `${height}vh`,
            ease: 'none',
            overwrite: true,
            scrollTrigger: {
                id: 'logo-footer',
                trigger: 'footer .footer-bottom',
                start: `${this.offsetBottom}px bottom`,
                end: `+=${this.gap}`,
                pin: true,
                scrub: true,
                invalidateOnRefresh: true,
                onUpdate: this.eventLogoScalingProgress,
            }
        });
    }

    eventLogoScalingProgress(tween) {
        if (window.lenis) {
            let event = new CustomEvent('Footer.LogoScalingUp', { detail: { progress: tween.progress }});
            window.dispatchEvent(event);
        }
    }

    focusContactBanner(){
        if(this.svgatorPlayer){
            if(this.svgatorPlayer.currentTime >= this.svgatorPlayer.totalTime){
                this.svgatorPlayer.seek(33);
                this.svgatorPlayer.play();
            }
        }
    }

    resize(force = false) {
        // Do not fire the resize calculations if the resize is just on the window height
        if (!force && window.innerWidth === this.windowWidth) {
            return false;
        }
        this.windowWidth = window.innerWidth;

        const ratio = window.innerWidth < 768 ? 3.39 : 6.86;
        gsap.set(this.el.querySelector('.scaling-logo'), {
            minHeight: window.innerWidth / ratio,
        });

        //calculate border radius to be proportional with size of button
        document.documentElement.style.setProperty(
            '--logo-border-radius',
            105 * ((window.innerWidth / 1440)),
        );

        //calculate margin-bottom based on size (60) in a resolution of 1440px
        document.documentElement.style.setProperty(
            '--logo-text-margin-bottom',
            60 * ((window.innerWidth / 1440)),
        );
        //calculate margin-bottom based on size (60) in a resolution of 1440px
        document.documentElement.style.setProperty(
            '--logo-text-margin-bottom-mobile',
            36 * ((window.innerWidth / 375)),
        );

        //offset used for start point of the scrolltrigger. Equal to the height of the animated element and the margin of the section
        this.offsetBottom = this.el.querySelector('.footer-bottom').offsetHeight + 40;
        //size of the window minus the header and the animated element. Gives us a value of the remaining space where the element should expand.
        this.gap = window.innerHeight - this.el.querySelector('.footer-bottom').offsetHeight - document.querySelector('header').offsetHeight;
        //for the small footer variant, the gap is smaller
        if (document.querySelector('.project-prefooter') && this.el.classList.contains('footer-small')) {
            // and x pixels added so that the percentage is below the header
            // let headerOffset = window.innerWidth < 768 ? 70 : 90;
            // this.gap -= document.querySelector('.project-prefooter').offsetHeight + headerOffset;
        }

        this.initScrollTrigger();
    }
}

Me.views['Footer'] = Footer;
