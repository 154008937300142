function importAll (r) {
    r.keys().forEach(r);
}

import '../scss/app.scss';
import './app.config';

//NODE_MODULES
import '@quatrecentquatre/manage-me';
import '@barba/core/dist/barba.umd';

//MAIN SCRIPTS
import './barba-navigation'
import './main'

//VIEWS
// automatic import
importAll(require.context('./views/', true, /\.js$/));

// or listed manually
// import './views/exemple';


// Only used in dev
if (module.hot) {
    module.hot.accept();
}
