import { ViewBasic } from '@quatrecentquatre/manage-me';

export class ServiceGallery extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['nextSlide', 'previousSlide']);

        let scope = this;

        this.index = 1;
        this.slideCount = this.el.querySelectorAll(
            '.images-ctn.large a',
        ).length;
        this.addEvents();
    }

    addEvents() {
        const next = this.el.querySelector('.next');
        if (next) {
            next.addEventListener('click', this.nextSlide);
        }
        const previous = this.el.querySelector('.previous');
        if (previous) {
            previous.addEventListener('click', this.previousSlide);
        }
    }

    removeEvents() {
        const next = this.el.querySelector('.next');
        if (next) {
            next.removeEventListener('click', this.nextSlide);
        }
        const previous = this.el.querySelector('.previous');
        if (previous) {
            previous.removeEventListener('click', this.previousSlide);
        }
    }

    /**
     * Increase index on next button
     * @param e
     */
    nextSlide(e) {
        this.index++;
        this.updateSlideshowStatus();
    }

    /**
     * Decrease index on previous button
     * @param e
     */
    previousSlide() {
        this.index--;
        this.updateSlideshowStatus();
    }

    /**
     * Update Slideshow status (progress / total slide) and animate the new active slides in.
     * @param e
     */
    updateSlideshowStatus() {
        this.el.querySelector('.next').disabled = false;
        this.el.querySelector('.previous').disabled = false;

        if (this.index === this.slideCount) {
            this.el.querySelector('.next').disabled = true;
        } else if (this.index === 1) {
            this.el.querySelector('.previous').disabled = true;
        }

        //reset active state
        this.el
            .querySelectorAll('.images-ctn a.active')
            .forEach(function (element) {
                element.classList.remove('active');
            });
        //add active state to new images

        this.el
            .querySelectorAll('.images-ctn.large a')
            [this.index - 1].classList.add('active');
        //sometimes the small image will not be available (end of slideshow)
        if (this.el.querySelectorAll('.images-ctn.small a')[this.index]) {
            this.el
                .querySelectorAll('.images-ctn.small a')
                [this.index].classList.add('active');
        }

        const activeImage = this.el.querySelectorAll('.images-ctn.large a')[
            this.index - 1
        ];

        //update index visible to the user
        this.el.querySelector('.index').innerHTML = this.index;
    }
}

Me.views['ServiceGallery'] = ServiceGallery;
