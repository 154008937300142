import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class CopyLink extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['clickHandler']);
        this.addEvents();
    }

    addEvents() {
        this.el.addEventListener('click', this.clickHandler);
    }

    removeEvents() {
        this.el.removeEventListener('click', this.clickHandler);
    }

    clickHandler() {
        navigator.clipboard.writeText(this.el.querySelector('.text').innerHTML);

        gsap.to(this.el.querySelector('.icon-copy'), {
            rotateY: 90,
            duration: 0.1,
            onComplete: () => {
                gsap.to(this.el.querySelector('.icon-checkmark'), {
                    rotateY: 0,
                    duration: 0.1,
                });
            },
        });
    }
}

Me.views['CopyLink'] = CopyLink;
