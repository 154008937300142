import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class ProjectPrefooter extends ViewBasic {
    constructor(options) {
        super(options);

        this.progressBar = null;
        this.progressLabel = null;
        this.isLoadingNextPage = false;
    }

    initialize() {
        this.bindAll(['updateProgressBar']);

        this.progressBar = this.el.querySelector('#project-progress-bar');
        this.progressLabel = this.el.querySelector('.progress-percent');
        this.isLoadingNextPage = false;

        this.addEvents();
    }

    addEvents() {
        //window.addEventListener('Footer.LogoScalingUp', this.updateProgressBar);
    }

    removeEvents() {
        // window.removeEventListener(
        //     'Footer.LogoScalingUp',
        //     this.updateProgressBar,
        // );
    }

    updateProgressBar(e) {
        if (!this.isLoadingNextPage) {
            const percentValue = Math.round(e.detail.progress * 100);

            // Animate the progress bar
            gsap.to(this.progressBar, {
                value: percentValue,
                duration: 0.1,
                snap: {
                    value: 1,
                },
            });

            // Change the label percentage
            this.progressLabel.innerText = percentValue + ' %';

            // Go to the next project
            if (percentValue === 100 && window.lenis.targetScroll >= window.lenis.limit) {
                this.removeEvents();
                this.isLoadingNextPage = true;
                this.el.querySelector('#next-project-link').click();
            }
        }
    }
}

Me.views['ProjectPrefooter'] = ProjectPrefooter;
