import { ViewBasic } from '@quatrecentquatre/manage-me';
import gsap from 'gsap';

export class Header extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        this.bindAll(['scroll', 'showNav', 'hideNav', 'toggleMobileNav']);
        this.addEvents();

        this.mobileNavAnimation = gsap.to(
            this.el.querySelectorAll('.nav li:not(.mobile-menu-extra)'),
            {
                opacity: 1,
                x: 0,
                stagger: 0.15,
                duration: 0.6,
                paused: true,
            },
        );
    }

    addEvents() {
        window.addEventListener('scroll', this.scroll);
        const burger = this.el.querySelector('.burger');
        if(burger){
            burger.addEventListener('mouseenter', this.showNav);
            burger.addEventListener('click', this.toggleMobileNav);
        }

        this.el.addEventListener('mouseleave', this.hideNav);
        this.el.querySelectorAll('a').forEach((link) => {
            link.addEventListener('click', this.toggleMobileNav);
        });
    }

    removeEvents() {
        window.removeEventListener('scroll', this.scroll);
        const burger = this.el.querySelector('.burger');
        burger.removeEventListener('mouseenter', this.showNav);
        burger.removeEventListener('click', this.toggleMobileNav);
        this.el.removeEventListener('mouseleave', this.hideNav);
    }

    scroll() {
        if (document.documentElement.scrollTop > 10) {
            this.el.classList.add('scrolled');
        } else {
            this.el.classList.remove('scrolled');
        }
    }

    showNav() {
        this.el.classList.add('nav-visible');
    }
    hideNav() {
        this.el.classList.remove('nav-visible');
    }

    toggleMobileNav() {
        if (window.innerWidth < 768) {
            this.el.classList.toggle('nav-opened');

            if (this.el.classList.contains('nav-opened')) {
                window.lenis.stop();

                this.mobileNavAnimation.restart();
            } else window.lenis.start();
        }
    }
}

Me.views['Header'] = Header;
